import api from "./api";

export const COLUMNS = [
  { label: "user name", accessor: (row: any) => row?.username },
  { label: "email", accessor: (row: any) => row?.email },
  {
    label: "User Status",
    accessKey: "active_status",
    accessor: (row: any) => row?.is_active,
  },
  {
    label: "Account Status",
    accessKey: "account_status",
    accessor: (row: any) => row?.user_profile?.account_status,
  },
  {
    label: "Credits (Per month)",
    accessKey: "total_credits",
    accessor: (row: any) => row?.user_profile?.total_songs_limit,
  },
  {
    label: "Remaining Credits (Per month)",
    accessKey: "remaining_credits",
    accessor: (row: any) => row?.user_profile?.remaing_songs_limit,
  },
  {
    label: "EXTRA CREDITS",
    accessKey: "extra_credits",
    accessor: (row: any) => row?.user_profile?.extra_credits,
  },
  // {
  //   label: "TRIAL CAMPAIGN LIMIT",
  //   accessKey: "trail_campaign_limit",
  //   accessor: (row: any) => row?.user_profile?.account_trials,
  // },
];

export const getAllUsers = async (page?: any, limit?: any, search?: any) => {
  const encodedSearch = encodeURIComponent(search);
  const response = await api.get(
    `/User/getUsersList/?page=${page}&page_size=${limit}&username=${encodedSearch}`
  );
  return response;
};

export const changeUserStatus = async (url: string) => {
  const response = await api.get(url);
  return response;
};

// export const changeUserIsDemo = async (url: string) => {
//   const response = await api.get(url);
//   return response;
// };

export const changeAccountStatus = async (url: string) => {
  const response = await api.get(url);
  return response;
};

export const changeUserSongsTrail = async (url: string) => {
  const response = await api.get(url);
  return response;
};

export const updateAccountTrial = async (
  userId: any,
  campaignLimit: any,
  accountType: any
) => {

  const response = await api.get(
    `/User/change_user_account_trial/?user_id=${userId}&account_trials=${campaignLimit}`
  );
  return response
};